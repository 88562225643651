import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";
import { Helmet } from "react-helmet";

const ServiceDetailsAbout = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
  <Helmet>
        <title>Our Services For Accounting Firms And Professionals -  Solemark accounts</title>
        <meta name="description" content="Welcome to  Solemark accounts: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta name="keywords" content="accounting, accounting firms, freelance accounting, collaborative partner, specialized services" />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Our Services For Accounting Firms And Professionals -  Solemark accounts" />
        <meta property="og:description" content="Welcome to  Solemark accounts: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta property="og:image" content="https://solemarkaccountants.com/assets/img/bg/firms.webp" />
        <meta property="og:url" content="https://solemarkaccountants.com/professionals-and-firms" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Services For Accounting Firms And Professionals -  Solemark accounts" />
        <meta name="twitter:description" content="Welcome to  Solemark accounts: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta name="twitter:image" content="https://solemarkaccountants.com/assets/img/bg/firms.webp" />
        <meta name="twitter:url" content="https://solemarkaccountants.com/professionals-and-firms" />
      </Helmet>
      <div className="tp-service-page ">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30"></div>
            <div className="col-lg-6 mb-30"></div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              
              <div className="tp-sv__content">
                <h3 className="tp-sv__subtitle">
                  {" "}
                  Sales and accounts receivable service
                </h3>
                <p className="text-secondary">
                  We offer comprehensive solutions to optimize your financial
                  operations and revenue cycle. Our services include invoice
                  preparation, entry, and emailing, customer statement
                  preparation and emailing, customer payments processing,
                  accounting software and sales software/POS reconciliation, and
                  payment reminder distribution. With our expertise, you can
                  streamline your financial processes, improve cash flow, and
                  build strong customer relationships. Let us take care of your
                  sales and accounts receivable needs while you focus on growing
                  your business. Contact us today for more information and
                  experience the benefits of our efficient and professional
                  services.{" "}
                </p>

                <h4 className="tp-sv__subtitle">
                  Expenses and accounts payable
                </h4>
                <p className="text-secondary">
                  We specialize in streamlining your accounts payable processes
                  to ensure accuracy, efficiency, and timely payments. Our
                  services include supplier bill checking and entering,
                  electronic payment preparation, supplier statement
                  reconciliation, and remittance distribution. With meticulous
                  attention to detail, we review and verify supplier bills,
                  enter them into your accounting system, and prepare electronic
                  payments securely and efficiently. Our team also reconciles
                  supplier statements, resolves discrepancies, and manages the
                  distribution of remittances, strengthening supplier
                  relationships. Trust us to handle your expenses and accounts
                  payable, allowing you to focus on core business activities.
                  Contact us today to experience the benefits of our
                  professional services in optimizing your financial operations.
                </p>

                <h4 className="tp-sv__subtitle">Payroll</h4>
                <p className="text-secondary">
                   Solemark accountants offers a comprehensive suite of
                  proficient services aimed at ensuring the seamless management
                  of your organization's payroll and employee financial matters.
                  Our array of specialized solutions encompasses the meticulous
                  upkeep of employee master files, precise payroll processing,
                  meticulous preparation of electronic payments, comprehensive
                  payroll reporting, timely distribution of pay slips,
                  meticulous adherence to superannuation compliance
                  requirements, precise PAYG compliance, and vigilant payroll
                  tax compliance. With a commitment to professionalism and
                  accuracy,  Solemark accounts stands as your trusted partner
                  in proficiently managing these critical aspects of your
                  business operations.
                </p>

                <h4 className="tp-sv__subtitle">
                  General accounting and advisory services
                </h4>
                <p className="text-secondary">
                   Solemark accounts excels in delivering a comprehensive
                  range of diligent financial services thoughtfully tailored to
                  enhance your business's fiscal management. Our specialized
                  offerings encompass the systematic preparation of month-end
                  journals, precise facilitation of bank reconciliation
                  processes, thorough reconciliation of control accounts,
                  methodical preparation of profit and loss statements and
                  balance sheets, insightful cashflow analysis, skillful
                  handling of budgeting, forecasting, and management report
                  creation, careful maintenance of fixed asset registers, and
                  accurate execution of product costing. With an unwavering
                  commitment to professionalism and exactitude, solemark  
                  Accountants stands poised to be your trusted partner in
                  advancing your financial management endeavors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default ServiceDetailsAbout;
