import React, { useState } from "react";
import VideoPopup from "../../common/Modals/VideoPopup";
import { Helmet } from "react-helmet";

const ServiceDetailsAboutTwo = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  return (
    <>
     <Helmet>
        <title>Our Services For Accounting Firms And Professionals - Solemark  Accountants</title>
        <meta name="description" content="Welcome to Solemark  Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta name="keywords" content="accounting, accounting firms, freelance accounting, collaborative partner, specialized services" />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Our Services For Accounting Firms And Professionals - Solemark  Accountants" />
        <meta property="og:description" content="Welcome to Solemark  Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta property="og:image" content="https://solemarkaccountants.com/assets/img/bg/firms.webp" />
        <meta property="og:url" content="https://solemarkaccountants.com/professionals-and-firms" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Services For Accounting Firms And Professionals - Solemark  Accountants" />
        <meta name="twitter:description" content="Welcome to Solemark  Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta name="twitter:image" content="https://solemarkaccountants.com/assets/img/bg/firms.webp" />
        <meta name="twitter:url" content="https://solemarkaccountants.com/professionals-and-firms" />
      </Helmet>
      <div className="tp-service-page ">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30"></div>
            <div className="col-lg-6 mb-30"></div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__subtitle">
                  {" "}
                  Welcome to Solemark  Accountants: Your Trusted Collaborative
                  Partner
                </h3>
                <p className="text-secondary text-justify">
                  At Solemark  Accountants, we believe in the power of
                  collaboration. We are thrilled to extend our hands in
                  partnership to fellow accounting firms and freelance
                  accounting professionals. Managing an accounting practice or
                  working as a freelance accounting professional can be
                  demanding, especially when it comes to staffing. We understand
                  the challenges you face, and that's why we offer a unique
                  solution – collaborating with us.
                  <br />
                  <br /> By teaming up with Solemark  Accountants, you can let go of
                  the burden of maintaining excessive staff while still
                  delivering exceptional services to your clients. Our
                  specialized collaborative approach allows you to tap into our
                  expertise and resources, ensuring seamless support for your
                  operations. One of the biggest advantages of this
                  collaboration is our special pricing for such engagements. We
                  are committed to providing value-added services while offering
                  significant cost benefits and savings. Instead of hiring
                  permanent staff, you can outsource to us and enjoy the
                  flexibility and financial advantages. Trust is the foundation
                  of any successful partnership, and we take great pride in
                  being a trusted collaborative partner. With years of
                  experience and a proven track record, we guarantee reliable,
                  efficient, and confidential handling of your clients'
                  financial matters.
                  <br /> <br />
                  At Solemark  Accountants, our goal is to strengthen your
                  accounting practice or freelance career by joining forces and
                  collectively achieving success. Let's embark on this journey
                  together and take your accounting endeavors to new heights.
                  Reach out to us today to explore the possibilities of
                  collaboration and unlock the full potential of your accounting
                  business or career.
                </p>

                <h4 className="tp-sv__subtitle">Partnering Options</h4>
                <p className="font-weight-bold custom sub">
                  SERVICE UNDER YOUR BRAND
                </p>
                <p className="text-secondary">
                  We will work under your brand as a part of your team. We will
                  raise an invoice to you for the services rendered. We will be
                  in touch with your teams for client communication. This
                  arrangement is mostly suitable for accounting firms that would
                  like to maintain control over client relationships.
                </p>

                <p className="font-weight-bold custom sub">
                  SERVICE UNDER OUR BRAND
                </p>
                <p className="text-secondary">
                  We will work under our brand, and we will invoice and
                  communicate directly with the client. We will make the necessary payments for
                  your invoice for the services rendered to the client. This
                  arrangement is mostly suitable for fast-growing freelance
                  accounting professionals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default ServiceDetailsAboutTwo;
