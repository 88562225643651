import React from "react";
import { Link } from "react-router-dom";

const Menus = () => {
  return (
    <ul className=" ml-130">
      <li>
        <Link to="/home">Home</Link>
      </li>
      <li>
        <Link to="/about">About us</Link>
      </li>
      <li>
        <Link to="/our-services">Our Services</Link>
      </li>
      <li>
        <Link to="/professionals-and-firms">FIRMS & PROFESSIONALS</Link>
      </li>
      <li>
        <Link to="/contact">Contact US</Link>
      </li>
      </ul>
  );
};

export default Menus;
