import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ServiceDetailsBannerTwo = () => {
  return (
    <>
    <Helmet>
        <title>Our Services For Accounting Firms And Professionals - Solemark   Accountants</title>
        <meta name="description" content="Welcome to Solemark Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta name="keywords" content="accounting, accounting firms, freelance accounting, collaborative partner, specialized services" />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Our Services For Accounting Firms And Professionals - Solemark   Accountants" />
        <meta property="og:description" content="Welcome to Solemark Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta property="og:image" content="https://solemarkaccountants.com/assets/img/bg/firms.webp" />
        <meta property="og:url" content="https://solemarkaccountants.com/professionals-and-firms" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Services For Accounting Firms And Professionals - Solemark   Accountants" />
        <meta name="twitter:description" content="Welcome to Solemark  Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta name="twitter:image" content="https://solemarkaccountants.com/assets/img/bg/firms.webp" />
        <meta name="twitter:url" content="https://solemarkaccountants.com/professionals-and-firms" />
      </Helmet>
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay service_banner"
        // data-background="assets/img/breadcrumb/breadcrumb-bg-2.webp"
        style={{
          backgroundImage: `url("assets/img/bg/firms.webp")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title">Our Services for <br/>Accounting Firms and Professionals</h3>
                <div className="breadcrumb__list">
                  <span>
                    <Link to="/home"></Link>
                  </span>
                  <span className="dvdr"></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetailsBannerTwo;
