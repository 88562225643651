import React from "react"; 
import Menu from "../../common/Menu/Menu";
import ContactInfo from "./ContactInfo";
import ContactUsBanner from "./ContactUsBanner";
import FooterTwo from "../../common/Footer/FooterTwo";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <>
    <Helmet>
    <title>Contact Us - Solemark Accountants</title>
    <meta
      name="description"
      content="Get in touch with Solemark Accountants. Contact us for accounting services, financial insights, and more.Contact Solemark Accountants - We are Online & Global. Phone Number: (08) 81217819. Mail Address: info@Solemarkaccountants.com"
    />
    <meta name="keywords" content="contact us, Solemark Accountants, accounting services, financial insights,contact, online, global, phone number, mail address, Solemark Accountants" />
    
    {/* Open Graph Tags for Social Media Sharing */}
    <meta property="og:title" content="Contact Us - Solemark Accountants" />
    <meta property="og:description" content="Get in touch with Solemark Accountants. Contact us for accounting services, financial insights, and more." />
    <meta property="og:image" content="https://solemarkaccountants.com/assets/img/bg/conatactus%20(1).webp" />
    <meta property="og:url" content="https://solemarkaccountants.com//contact" />
  </Helmet>
      <Menu header_style_2={true} />
      <ContactUsBanner />
      <ContactInfo />
      <FooterTwo />
    </>
  );
};

export default ContactUs;
