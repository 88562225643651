import React from "react";

const FooterTwo = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-30"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="top-footer-copyright pt-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="tp-copyrigh-text mb-30">
                <span className="text-white">
                  © {new Date().getFullYear()} Solemark Accountants - All Rights Reserved.
                </span>
              </div>
              <p style={{color:'white', fontSize:'13px'}}>Developed by <a href="https://3dhdesign.com/">3DH Design</a></p>
            </div>
            <div className="col-md-4 col-12">
              <div className="tp-footer-social-icon mb-30 text-md-end">
                <ul>
                  <li>
                    <a href="https://web.facebook.com/profile.php?id=100094229631583">
                      <i className="fab fa-facebook text-white"></i>
                    </a>
                  </li>

                  <li>
                    <a href="https://www.linkedin.com/company/Solemarkaccountants/about/">
                      <i className="fab fa-linkedin text-white"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTwo;
