import React from "react";
import Contact from "../../common/Contact/Contact";
import FooterTwo from "../../common/Footer/FooterTwo";
import Menu from "../../common/Menu/Menu";
import ServiceDetailsAbout from "./ServiceDetailsAbout";
import ServiceDetailsBanner from "./ServiceDetailsBanner";
import { Helmet } from "react-helmet";

const ServiceDetails = () => {
  return (
    <>
    <Helmet>
        <title>Our Services For Accounting Firms And Professionals - Solemark Accountants</title>
        <meta name="description" content="Welcome to Solemark Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta name="keywords" content="accounting, accounting firms, freelance accounting, collaborative partner, specialized services" />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="Our Services For Accounting Firms And Professionals - Solemark Accountants" />
        <meta property="og:description" content="Welcome to Solemark Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta property="og:image" content="https://solemarkaccountants.com/assets/img/bg/firms.webp" />
        <meta property="og:url" content="https://solemarkaccountants.com/professionals-and-firms" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Our Services For Accounting Firms And Professionals - Solemark Accountants" />
        <meta name="twitter:description" content="Welcome to Solemark Accountants: Your Trusted Collaborative Partner. We offer a unique solution for accounting firms and freelance professionals to collaborate and deliver exceptional services with cost benefits and savings." />
        <meta name="twitter:image" content="https://solemarkaccountants.com/assets/img/bg/firms.webp" />
        <meta name="twitter:url" content="https://solemarkaccountants.com/professionals-and-firms" />
      </Helmet>
      <Menu header_style_2={true} />
      <ServiceDetailsBanner />
      <ServiceDetailsAbout />
      <Contact />
      <FooterTwo />
    </>
  );
};

export default ServiceDetails;
