import React from "react";

const TeamDetailsAbout = () => {
  return (
    <>
      <div className="team-main-content pb-130">
        <div className="container">
          <div className="team-page-content pr-100">
            <div className="row">
              <div className="col-12">
                <div className="team-page-text">
                  <h3 className="team-title-text mb-25">About Hilextina</h3>
                  <p className="mb-25">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem. Ut enim
                    ad minima veniam.
                  </p>
                  <p className="mb-90">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 pb-30">
                <div className="team-member-skill">
                  <h3 className="team-title-text mb-25">Professional Skills</h3>
                  <div className="tp-promotion-progress pr-130">
                    <div className="tp-progress-item p-relative mb-45">
                      <h4>Invest Consulting</h4>
                      <span className="tp-progress-count">85%</span>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-duration="1s"
                          data-wow-delay=".3s"
                          role="progressbar"
                          data-width="85%"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: "85%",
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.3s",
                            animationName: "slideInLeft",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="tp-progress-item p-relative mb-45">
                      <h4>Business &amp; Finance</h4>
                      <span className="tp-progress-count mb30">90%</span>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-duration="1s"
                          data-wow-delay=".3s"
                          role="progressbar"
                          data-width="90%"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: "90%",
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.3s",
                            animationName: "slideInLeft",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="tp-progress-item p-relative mb-45">
                      <h4>Hr Consulting</h4>
                      <span className="tp-progress-count">70%</span>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-duration="1s"
                          data-wow-delay=".3s"
                          role="progressbar"
                          data-width="70%"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: "70%",
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.3s",
                            animationName: "slideInLeft",
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="tp-progress-item p-relative mb-45">
                      <h4>Communication Skills</h4>
                      <span className="tp-progress-count">70%</span>
                      <div className="progress">
                        <div
                          className="progress-bar wow slideInLeft"
                          data-wow-duration="1s"
                          data-wow-delay=".3s"
                          role="progressbar"
                          data-width="70%"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: "70%",
                            visibility: "visible",
                            animationDuration: "1s",
                            animationDelay: "0.3s",
                            animationName: "slideInLeft",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <h3 className="team-title-text mb-25">About Hilextina</h3>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur magni dolores eos qui ratione voluptatem sequi
                  nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
                  dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora incidunt ut labore et dolore magnam
                  aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                  nostrum Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illototam rem aperiam, eaque ipsa
                  ab illototam rem
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="team-page-text">
                  <h3 className="team-title-text mb-30">About Hilextina</h3>
                  <p className="mb-25">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem. Ut enim
                    ad minima veniam, quis nostrum exercitationem ullam corporis
                    suscipit laboriosam, nisi ut aliquid ex ea commodi
                    consequatur? Quis autem vel eum iure reprehenderit qui in ea
                    voluptate.
                  </p>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetailsAbout;
