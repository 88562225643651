import React from "react"; 
import FooterTwo from "../../common/Footer/FooterTwo";
import Menu from "../../common/Menu/Menu"; 
import AboutBanner from "./AboutBanner";
import AboutCompany from "./AboutCompany"; 
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Solemark Accountants</title>
        <meta name="description" content="Solemark Accountants is a team of highly skilled Chartered Accountants and Members of the Institute of Public Accountants of Australia. We specialize in Xero, MYOB, and QuickBooks accounting packages, ensuring efficient management of your financial data.Learn about Solemark Accountants, a team of skilled Chartered Accountants providing top-notch financial services." />
        <meta name="keywords" content="accounting, financial services, Chartered Accountants, financial insights, strategic decision-making,accountants, Xero, MYOB, QuickBooks, financial data, Chartered Accountants, Institute of Public Accountants, Australia" />
        {/* Open Graph Tags for Social Media Sharing */}
        <meta property="og:title" content="About Our Company - Solemark Accountants" />
        <meta property="og:description" content="Discover Solemark Accountants – Trusted Chartered Accountants offering top-notch financial services. Unlock insights for strategic decision-making." />
        <meta property="og:image" content="https://solemarkaccountants.com/assets/img/bg/aboutus2.webp" />
        <meta property="og:url" content="https://solemarkaccountants.com/about" />
      </Helmet>
      <Menu header_style_2={true} />
      <AboutBanner />
      <AboutCompany />
      <FooterTwo />
    </>
  );
};

export default About;
