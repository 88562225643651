import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ContactUsBanner = () => {
  return (
    <>
     <Helmet>
    <title>Contact Us - Solemark Accountants</title>
    <meta
      name="description"
      content="Get in touch with Solemark Accountants. Contact us for accounting services, financial insights, and more.Contact Solemark Accountants - We are Online & Global. Phone Number: (08) 81217819. Mail Address: info@Solemarkaccountants.com"
    />
    <meta name="keywords" content="contact us, Solemark Accountants, accounting services, financial insights,contact, online, global, phone number, mail address, Solemark Accountants" />
    
    {/* Open Graph Tags for Social Media Sharing */}
    <meta property="og:title" content="Contact Us - Solemark Accountants" />
    <meta property="og:description" content="Get in touch with Solemark Accountants. Contact us for accounting services, financial insights, and more." />
    <meta property="og:image" content="https://solemarkaccountants.com/assets/img/bg/conatactus%20(1).webp" />
    <meta property="og:url" content="https://solemarkaccountants.com//contact" />
  </Helmet>
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay contact_us_banner"
        // data-background="assets/img/breadcrumb/breadcrumb-bg-2.webp"
        style={{
          backgroundImage: `url("assets/img/bg/conatactus (1).webp")`, backgroundPosition: 'center 20% ',  
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title"style={{ textShadow: '5px 5px 8px rgba(0, 0, 0, 0.5)' }}>contact</h3>
                <div className="breadcrumb__list">
                  <span>
                    <Link to="/"></Link>
                  </span>
                  <span className="dvdr"></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsBanner;
