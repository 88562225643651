import { useRef } from "react";
import slider2 from "../../data/SliderHomePageTwo.json";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

const settings = {
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  fade: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
      },
    },
  ],
};

const SliderHomeTwo = () => {
  const sliderRef = useRef(null);

  const additionalInfo =
  "Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support necessary to help you reach peak success.";

const clientTestimonials = [
  "It’s a pleasure to work with Solemark Accountants with a high level of customer service while maintaining high quality of bookkeeping services. Their experience is reflected in their efficiency and quality of work. Very friendly and professional. Highly recommended.",
  "As a tax accountant, I have referred many clients to Solemark Accountants to do their bookkeeping in a proper manner. I can highly recommend them for their quality of work, flexibility, and professionalism.",
  "Great service offering with a very competent and flexible team at Solemark Accountants. Really happy to recommend to someone else.",
];
  return (
    <>
      <Helmet>
        <title>
          Solemark Accountants - Your Next-Generation Accounting Partner
        </title>
        <meta
          name="description"
          content="We increase your business! Why choose Solemark Accountants? Professional accountants backed by years of proven expertise and industry knowledge.
           Revolutionizing accounting with innovative, tech-driven solutions. Tailored solutions for your unique accounting needs. Beyond bookkeeping: Adding strategic
            value to your business. Commitment to on-time delivery: Timely insights for informed decision-making. Introducing Solemark Accountants, your Next-Generation 
            accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you 
            compliant while also providing crucial business advisory and accounting support to help you reach peak success. We will help to run your business efficiently. 
            Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!We increase 
            your business with comprehensive solutions to optimize your financial operations and revenue cycle. Streamline your financial processes, improve cash flow, and 
            build strong customer relationships. Contact us today for efficient and professional services."
        />
        <meta name="additional-info" content={additionalInfo} />
        {clientTestimonials.map((testimonial, index) => (
          <meta
            name={`client-testimonial-${index + 1}`}
            content={testimonial}
            key={index}
          />
        ))}
        {/* Open Graph Tags for Social Media Sharing */}
        <meta
          property="og:title"
          content="Solemark Accountants - Your Next-Generation Accounting Partner"
        />
        <meta
          property="og:description"
          content="Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support to help you reach peak success. Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!"
        />
        <meta property="og:url" content="https://Solemarkaccountants.com/" />{" "}
        {/* Update with your actual URL */}
        <meta
          property="og:image"
          content="https://solemarkaccountants.com/assets/img/bg/working-from-home-2022-11-16-14-00-43-utc.webp"
        />{" "}
        {/* Update with your actual image URL */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Solemark Accountants" />
      </Helmet>
      {/* <!-- slider-2 --> */}
      <div className="slider-home-two">
        <div className="tp-slider-area-2 p-relative">
          <div className="tp-silder-acive-2 ">
            <Slider ref={sliderRef} {...settings}>
              {slider2?.slider2.map((item) => (
                <div key={item.id} className="tp-slider-item-2 w-img">
                  <img src={item?.img} alt="img" />
                </div>
              ))}
            </Slider>
          </div>
          
        </div>
      </div>
      {/* <!-- slider -2 --> */}
    </>
  );
};

export default SliderHomeTwo;
