import React from "react";
import { Helmet } from 'react-helmet';

const AboutCompany = () => {
  return (
    <>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Solemark Accountants</title>
        <meta name="description" content="Solemark Accountants is a team of highly skilled Chartered Accountants and Members of the Institute of Public Accountants of Australia. We specialize in Xero, MYOB, and QuickBooks accounting packages, ensuring efficient management of your financial data.Learn about Solemark Accountants, a team of skilled Chartered Accountants providing top-notch financial services." />
        <meta name="keywords" content="accounting, financial services, Chartered Accountants, financial insights, strategic decision-making,accountants, Xero, MYOB, QuickBooks, financial data, Chartered Accountants, Institute of Public Accountants, Australia" />
        {/* Open Graph Tags for Social Media Sharing */}
        <meta property="og:title" content="About Our Company - Solemark Accountants" />
        <meta property="og:description" content="Discover Solemark Accountants – Trusted Chartered Accountants offering top-notch financial services. Unlock insights for strategic decision-making." />
        <meta property="og:image" content="https://Solemarkaccountants.com/assets/img/bg/aboutus2.webp" />
        <meta property="og:url" content="https://Solemarkaccountants.com/about" />
      </Helmet>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span>
                  <h2 className="tp-pt-size mb-40">
                    Our business boasts top of the line services
                  </h2>
                </div>
                <div>
                  <p>
                    {" "}
                    Solemark Accountants is a team of highly skilled Chartered
                    Accountants and Members of the Institute of Public
                    Accountants of Australia. Our services cater to a wide range of
                    industries, including trading, manufacturing, service,
                    agriculture, financial services, hospitality, healthcare,
                    real estate, and non-profit organizations. We specialize in
                    Xero, MYOB, and QuickBooks accounting packages, ensuring
                    efficient management of your financial data. <br />
                    <br />
                    As your trusted business partner, we offer invaluable
                    insights to aid in strategic decision-making, supporting
                    your business's growth and success. At Solemark Accountants, we
                    pride ourselves on our flexibility and innovative approach.
                    We tailor our services to meet your unique requirements,
                    regardless of whether you're a start-up or an established
                    enterprise.
                  </p>
                  <br />
                  <h1>Our Mission</h1>
                  <p>
                    Our mission is to empower businesses by providing virtual
                    accounting assistants that streamline financial processes,
                    enhance productivity, and deliver accurate and timely
                    financial information. We strive to revolutionize the way
                    companies manage their accounting tasks, enabling them to
                    focus on core operations and achieve sustainable growth.
                  </p>
                  <h1>Our Vision</h1>
                  <p>
                    Our vision is to be the leading provider of virtual
                    accounting assistants, transforming the financial landscape
                    for businesses worldwide. We aim to leverage cutting-edge
                    technologies and innovative solutions to become the go-to
                    platform for businesses seeking efficient and reliable
                    virtual accounting support. By continuously improving our
                    services, we aspire to set new industry standards and drive
                    the digital transformation of accounting processes.
                  </p>
                </div>
                
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="assets/img/about/about-page.webp" alt="" />
                </div>
                <div className="dots-img">
                  <img src="assets/img/about/dot.webp" alt="" />
                </div>
                <div className="about-info-box d-flex flex-column justify-content-center text-center">
                  <h3 className="box-title">15</h3>
                  <h4 className="box-subtitle">
                    Years of <br />
                    Experience
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
