import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// single service
export function ServiceItem({ icon, title, desc }) {

  const additionalInfo =
  "Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support necessary to help you reach peak success.";

const clientTestimonials = [
  "It’s a pleasure to work with Solemark Accountants with a high level of customer service while maintaining high quality of bookkeeping services. Their experience is reflected in their efficiency and quality of work. Very friendly and professional. Highly recommended.",
  "As a tax accountant, I have referred many clients to Solemark Accountants to do their bookkeeping in a proper manner. I can highly recommend them for their quality of work, flexibility, and professionalism.",
  "Great service offering with a very competent and flexible team at Solemark Accountants. Really happy to recommend to someone else.",
];

  return (

    
    <div className="col-xl-3 col-md-6">
      <Helmet>
        <title>
          Solemark Accountants - Your Next-Generation Accounting Partner
        </title>
        <meta
          name="description"
          content="We increase your business! Why choose Solemark Accountants? Professional accountants backed by years of proven expertise and industry knowledge. Revolutionizing accounting with innovative, tech-driven solutions. Tailored solutions for your unique accounting needs. Beyond bookkeeping: Adding strategic value to your business. Commitment to on-time delivery: Timely insights for informed decision-making. Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support to help you reach peak success. We will help to run your business efficiently. Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!We increase your business with comprehensive solutions to optimize your financial operations and revenue cycle. Streamline your financial processes, improve cash flow, and build strong customer relationships. Contact us today for efficient and professional services."
        />
        <meta name="additional-info" content={additionalInfo} />
        {clientTestimonials.map((testimonial, index) => (
          <meta
            name={`client-testimonial-${index + 1}`}
            content={testimonial}
            key={index}
          />
        ))}
        {/* Open Graph Tags for Social Media Sharing */}
        <meta
          property="og:title"
          content="Solemark Accountants - Your Next-Generation Accounting Partner"
        />
        <meta
          property="og:description"
          content="Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support to help you reach peak success. Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!"
        />
        <meta property="og:url" content="https://yourwebsite.com/" />{" "}
        {/* Update with your actual URL */}
        <meta
          property="og:image"
          content="https://yourwebsite.com/og-image.jpg"
        />{" "}
        {/* Update with your actual image URL */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Solemark Accountants" />
      </Helmet>
      <div className="tp-service text-center white-bg pt-60 pb-185 pl-25 pr-25 mb-30">
        <div className="tp-service__icon">
          <i className={icon}></i>
        </div>
        <h3 className="tp-service__title pt-40 pb-25">
          <Link to="/our-services">{title}</Link>
        </h3>
        <p className="text-black">{desc}</p>
        <Link className="tp-btn-sm-black" to="/our-services">
          <span>
            <svg
              width="36"
              height="8"
              viewBox="0 0 36 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                fill="currentColor"
              />
            </svg>
            <svg
              width="36"
              height="8"
              viewBox="0 0 36 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </Link>
      </div>
    </div>
  );
}

export function ServiceItem1({ icon, title, desc }) {
  return (
    <div className="col-xl-3 col-md-6">
      <div className="tp-service text-center white-bg pt-60 pb-55 pl-25 pr-25 mb-30">
        <div className="tp-service__icon">
          <i className={icon}></i>
        </div>
        <h3 className="tp-service__title pt-40 pb-25">
          <Link to="/our-services">{title}</Link>
        </h3>
        <p className="text-black">{desc}</p>
        <Link className="tp-btn-sm-black" to="/our-services">
          <span>
            <svg
              width="36"
              height="8"
              viewBox="0 0 36 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                fill="currentColor"
              />
            </svg>
            <svg
              width="36"
              height="8"
              viewBox="0 0 36 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </Link>
      </div>
    </div>
  );
}


export function ServiceItem2({ icon, title, desc }) {
  return (
    <div className="col-xl-3 col-md-6">
      <div className="tp-service text-center white-bg pt-60 pb-180 pl-25 pr-25 mb-30">
        <div className="tp-service__icon">
          <i className={icon}></i>
        </div>
        <h3 className="tp-service__title pt-40 pb-25">
          <Link to="/our-services">{title}</Link>
        </h3>
        <p className="text-black">{desc}</p>
        <Link className="tp-btn-sm-black" to="/our-services">
          <span>
            <svg
              width="36"
              height="8"
              viewBox="0 0 36 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                fill="currentColor"
              />
            </svg>
            <svg
              width="36"
              height="8"
              viewBox="0 0 36 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </Link>
      </div>
    </div>
  );
}



const Services = () => {
  const additionalInfo =
  "Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support necessary to help you reach peak success.";

const clientTestimonials = [
  "It’s a pleasure to work with Solemark Accountants with a high level of customer service while maintaining high quality of bookkeeping services. Their experience is reflected in their efficiency and quality of work. Very friendly and professional. Highly recommended.",
  "As a tax accountant, I have referred many clients to Solemark Accountants to do their bookkeeping in a proper manner. I can highly recommend them for their quality of work, flexibility, and professionalism.",
  "Great service offering with a very competent and flexible team at Solemark Accountants. Really happy to recommend to someone else.",
];
  return (
    <>
     <Helmet>
        <title>
          Solemark Accountants - Your Next-Generation Accounting Partner
        </title>
        <meta
          name="description"
          content="We increase your business! Why choose Solemark Accountants? Professional accountants backed by years of proven expertise and industry knowledge.
           Revolutionizing accounting with innovative, tech-driven solutions. Tailored solutions for your unique accounting needs. Beyond bookkeeping: Adding strategic
            value to your business. Commitment to on-time delivery: Timely insights for informed decision-making. Introducing Solemark Accountants, your Next-Generation 
            accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you 
            compliant while also providing crucial business advisory and accounting support to help you reach peak success. We will help to run your business efficiently. 
            Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!We increase 
            your business with comprehensive solutions to optimize your financial operations and revenue cycle. Streamline your financial processes, improve cash flow, and 
            build strong customer relationships. Contact us today for efficient and professional services."
        />
        <meta name="additional-info" content={additionalInfo} />
        {clientTestimonials.map((testimonial, index) => (
          <meta
            name={`client-testimonial-${index + 1}`}
            content={testimonial}
            key={index}
          />
        ))}
        {/* Open Graph Tags for Social Media Sharing */}
        <meta
          property="og:title"
          content="Solemark Accountants - Your Next-Generation Accounting Partner"
        />
        <meta
          property="og:description"
          content="Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support to help you reach peak success. Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!"
        />
        <meta property="og:url" content="https://Solemarkaccountants.com/" />{" "}
        {/* Update with your actual URL */}
        <meta
          property="og:image"
          content="https://Solemarkaccountants.com/assets/img/bg/working-from-home-2022-11-16-14-00-43-utc.webp"
        />{" "}
        {/* Update with your actual image URL */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Solemark Accountants" />
      </Helmet>
      {/* <!-- service area start  --> */}
      <div className="tp-service-ara grey-bg-4 pt-140 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-25"></span>
                <h2 className="tp-section-title">Our Services</h2>
                <p className="text-black"> </p>
              </div>
            </div>
          </div>
          <div className="row">
            <ServiceItem
              icon="pe-7s-graph1"
              title="Sales and accounts receivable"
              desc={
                <>
                  <ul className="text-black">
                    <li>- Invoice preparation, entry and emailing</li>
                    <li>- Customer statement preparation and emailing</li>
                    <li>- Customer payments processing</li>
                    <li>- Accounting software and sales software/POS reconciliation</li>
                    <li>-Payment reminder distribution</li>
                  </ul>
                </>
              }
            />
            <ServiceItem2
              icon="pe-7s-display1"
              title="Expenses and accounts payable"
              desc={
                <>
                  <ul className="text-black">
                    <li>- Supplier bill checking and entering</li>
                    <li>- Electronic payment preparation</li>
                    <li>- Supplier statement reconciliation</li>
                    <li>- Remittance distribution</li>
                   
                  </ul>
                </>
              }
            />
            <ServiceItem
              icon="pe-7s-cash"
              title="Payroll"
              desc={
                <>
                  <ul className="text-black">
                    <li>- Maintenance of employee master file</li>
                    <li>- Payroll processing</li>
                    <li>- Electronic payment preparation</li>
                    <li>- Payroll reporting</li>
                    <li>- Distribution of pay slips</li>
                    <li>- Superannuation compliance</li>     
                    <li>- PAYG compliance</li>   
                    <li>- Payroll Tax compliance</li>          
                    </ul>
                </>
              }
            />
            <ServiceItem1
              icon="pe-7s-calculator"
              title="General accounting and advisory services"
              desc={
                <>
                  <ul className="text-black">
                    <li>- Prepare month end journals</li>
                    <li>- Bank reconciliations preparations</li>
                    <li>- Control accounts reconciliation</li>
              
                    <li>- Profit and loss statement and balance sheet preparation</li>
                    <li>- Cashflow analysis </li>     
                    <li>- Budgeting, forecasting & management report preparation</li>
                    <li>- Maintain fixed asset register</li>
                    <li>- Product costing</li>  
                    </ul>
                </>
              }
            />
            {/* <div className="text-center mt-60">
              <Link to="/service" className="tp-btn-border">
                All Service
                <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* <!-- service area end  -->   */}
    </>
  );
};

export default Services;
