import React from "react"; 
import FooterTwo from "../common/Footer/FooterTwo";
import FullScreenBtn from "../common/FullScreenBtn";
import Menu from "../common/Menu/Menu";
import Testimonial from "../common/Testimonial/Testimonial";
 
import Services from "./Services";
import SliderHomeTwo from "./SliderHomeTwo";
import TopCategoryHomePageTwo from "./TopCategoryHomePageTwo";
import TopCtaAreaTwo from "./TopCtaAreaTwo";
import { Helmet } from "react-helmet";

const HomeTwo = () => {
  const additionalInfo =
  "Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support necessary to help you reach peak success.";

const clientTestimonials = [
  "It’s a pleasure to work with Solemark Accountants with a high level of customer service while maintaining high quality of bookkeeping services. Their experience is reflected in their efficiency and quality of work. Very friendly and professional. Highly recommended.",
  "As a tax accountant, I have referred many clients to Solemark Accountants to do their bookkeeping in a proper manner. I can highly recommend them for their quality of work, flexibility, and professionalism.",
  "Great service offering with a very competent and flexible team at Solemark Accountants. Really happy to recommend to someone else.",
];

  return (
    <>
     <Helmet>
        <title>
          Solemark Accountants - Your Next-Generation Accounting Partner
        </title>
        <meta
          name="description"
          content="Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia.
           With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing the crucial business advisory,
            and accounting support necessary to help you reach peak success. We help to run your business efficiently. Your Trusted Accounting Partner, Preferred by 
            Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today! We increase your business with comprehensive solutions to 
            optimize your financial operations and revenue cycle. Streamline your financial processes, improve cash flow, and build strong customer relationships. Contact us today for 
            efficient and professional services. Discover prime accounts, find the best Australian accountant, partner with the top Australian accountancy agency, explore prime account 
            Australia services, consult a reliable Australia accountant, optimize your business with prime way accountant services, experience the excellence of Solemark accountant services, 
            elevate your business with Solemark accountants, manage your accounts with Solemark accounts solutions, unlock the potential of Solemark accounts australia services, embrace online 
            accounting for seamless operations, and choose a virtual accountant for convenient financial management."
        />
        <meta name="additional-info" content={additionalInfo} />
        {clientTestimonials.map((testimonial, index) => (
          <meta
            name={`client-testimonial-${index + 1}`}
            content={testimonial}
            key={index}
          />
        ))}
        {/* Open Graph Tags for Social Media Sharing */}
        <meta
          property="og:title"
          content="Solemark Accountants - Your Next-Generation Accounting Partner"
        />
        <meta
          property="og:description"
          content="Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support to help you reach peak success. Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!"
        />
        <meta property="og:url" content="https://solemarkaccountants.com/" />{" "}
        {/* Update with your actual URL */}
        <meta
          property="og:image"
          content="https://solemarkaccountants.com/assets/img/bg/working-from-home-2022-11-16-14-00-43-utc.webp"
        />{" "}
        {/* Update with your actual image URL */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Solemark Accountants" />
      </Helmet>
      <Menu header_style_2={true} />
      <TopCtaAreaTwo />
      <SliderHomeTwo />
      <Services />
      <TopCategoryHomePageTwo />
      
      <Testimonial />
    
     
      <FullScreenBtn />
      <FooterTwo />
    </>
  );
};

export default HomeTwo;
