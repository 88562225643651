import React from "react";
import { Helmet } from "react-helmet";

const AboutBanner = () => {
  return (
    <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Solemark Accountants</title>
        <meta name="description" content="Solemark Accountants is a team of highly skilled Chartered Accountants and Members of the Institute of Public Accountants of Australia. We specialize in Xero, MYOB, and QuickBooks accounting packages, ensuring efficient management of your financial data.Learn about Solemark Accountants, a team of skilled Chartered Accountants providing top-notch financial services." />
        <meta name="keywords" content="accounting, financial services, Chartered Accountants, financial insights, strategic decision-making,accountants, Xero, MYOB, QuickBooks, financial data, Chartered Accountants, Institute of Public Accountants, Australia" />
        {/* Open Graph Tags for Social Media Sharing */}
        <meta property="og:title" content="About Our Company - Solemark Accountants" />
        <meta property="og:description" content="Discover Solemark Accountants – Trusted Chartered Accountants offering top-notch financial services. Unlock insights for strategic decision-making." />
        <meta property="og:image" content="https://Solemarkaccountants.com/assets/img/bg/aboutus2.webp" />
        <meta property="og:url" content="https://Solemarkaccountants.com/about" />
      </Helmet>
      {/* <!-- breadcrumb area start --> */}
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay about_banner"
        // data-background="assets/img/breadcrumb/breadcrumb-bg-2.webp"
        style={{
          backgroundImage: `url("assets/img/bg/aboutus2.webp")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title">About us</h3>
                <div className="breadcrumb__list">
                  <span>
                     
                  </span>
                  <span className="dvdr"></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb area end --> */}
    </>
  );
};

export default AboutBanner;
