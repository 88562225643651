const menu_data = [
  {
    id: 1,
    hasDropdown: true,
    title: 'Home',
    link: '/home',
    submenus: [
      
     
    ]
  },
  {
    id: 2,
    title: 'About Us ',
    link: '/about',
  },
  {
    id: 3,
    hasDropdown: true,
    title: 'Our Services',
    link: '/our-services',
    submenus: [
      
    ]
  },
  {
    id: 4,
    hasDropdown: true,
    title: 'Accounting Firms',
    link: '/professionals-and-firms',
    submenus: [
     
    ]
  },
  {
    id: 5,
    hasDropdown: true,
    title: 'Contact Us',
    link: '/contact',
    submenus: [
      
    ]
  },
]

export default menu_data;
