import React from "react";
import { Helmet } from "react-helmet"; 


const TopCategoryHomePageTwo = () => {
  const additionalInfo =
  "Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support necessary to help you reach peak success.";

const clientTestimonials = [
  "It’s a pleasure to work with Solemark Accountants with a high level of customer service while maintaining high quality of bookkeeping services. Their experience is reflected in their efficiency and quality of work. Very friendly and professional. Highly recommended.",
  "As a tax accountant, I have referred many clients to Solemark Accountants to do their bookkeeping in a proper manner. I can highly recommend them for their quality of work, flexibility, and professionalism.",
  "Great service offering with a very competent and flexible team at Solemark Accountants. Really happy to recommend to someone else.",
];
  return (
    <>
        <Helmet>
        <title>
          Solemark Accountants - Your Next-Generation Accounting Partner
        </title>
        <meta
          name="description"
          content="We increase your business! Why choose Solemark Accountants? Professional accountants backed by years of proven expertise and industry knowledge.
           Revolutionizing accounting with innovative, tech-driven solutions. Tailored solutions for your unique accounting needs. Beyond bookkeeping: Adding strategic
            value to your business. Commitment to on-time delivery: Timely insights for informed decision-making. Introducing Solemark Accountants, your Next-Generation 
            accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you 
            compliant while also providing crucial business advisory and accounting support to help you reach peak success. We will help to run your business efficiently. 
            Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!We increase 
            your business with comprehensive solutions to optimize your financial operations and revenue cycle. Streamline your financial processes, improve cash flow, and 
            build strong customer relationships. Contact us today for efficient and professional services."
        />
        <meta name="additional-info" content={additionalInfo} />
        {clientTestimonials.map((testimonial, index) => (
          <meta
            name={`client-testimonial-${index + 1}`}
            content={testimonial}
            key={index}
          />
        ))}
        {/* Open Graph Tags for Social Media Sharing */}
        <meta
          property="og:title"
          content="Solemark Accountants - Your Next-Generation Accounting Partner"
        />
        <meta
          property="og:description"
          content="Introducing Solemark Accountants, your Next-Generation accountants dedicated to serving small businesses across Australia. With a team of experienced accountants, we offer a full suite of services to keep you compliant while also providing crucial business advisory and accounting support to help you reach peak success. Your Trusted Accounting Partner, Preferred by Businesses Across Australia. Drive Success with Solemark Accountants: Transform Your Business Today!"
        />
        <meta property="og:url" content="https://solemarkaccountants.com/" />{" "}
        {/* Update with your actual URL */}
        <meta
          property="og:image"
          content="https://solemarkaccountants.com/assets/img/bg/working-from-home-2022-11-16-14-00-43-utc.webp"
        />{" "}
        {/* Update with your actual image URL */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Solemark Accountants" />
      </Helmet>
      <div className="tp-cta-area-2 grey-bg-4 ">
        <div className="container-fluid p-0 ">
          <div className="row g-0 ">
            <div className="col-lg-12 col-xl-4 ">
              <div className="tp-cta-2-left black-bg pt-135 pr-30 pl-90 pb-135 ">
                <div className="tp-section-wrapper ">
                  <span className="tp-section-subtitle mb-20 text-white " style={{}}>
                    we will help to run your business efficiently
                  </span>
                  <h2 className="tp-section-title text-white tp-title-size ">
                    Your Trusted Accounting Partner, Preferred by Businesses
                    Across Australia
                  </h2>
                  <p className="text-white">
                    Looking for a better way to handle your essential business
                    services? Consider it DONE.
                  </p>
                  <p className="text-white homepagecontent">
                    Introducing Solemark Accountants, your Next-Generation
                    accountants dedicated to serving small businesses across
                    Australia. With a team of experienced accountants, we offer
                    a full suite of services to keep you compliant while also
                    providing the crucial business advisory, and accounting
                    support necessary to help you reach peak success.
                  </p>
                  <p className="text-white">
                    At Solemark Accountants, we understand that meaningful
                    numbers create real value for your business. That's why we
                    go beyond traditional accounting services to offer
                    insightful financial analysis and reporting that can drive
                    your business forward. Leave the burden of bookkeeping to
                    us, and you can focus on what you do best: growing your
                    business. We empower you to focus on what matters
                    most—delighting customers and accelerating growth. We give
                    you back time and peace of mind by taking care of your back
                    office so you can focus on taking your business to the next
                    level.{" "}
                  </p>
                  <p className="text-white">
                    With our expertise and dedication to helping startups
                    thrive, we are committed to being your trusted partner every
                    step of the way. Experience the difference of working with
                    Solemark Accountants and unlock the full potential of your
                    business.{" "}
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="tp-cta-img w-img">
                <img src="assets/img/gallery/portrait-of-a-young-support-agent-working-on-a-lap-2023-01-04-20-33-31-utc.webp" alt="" />
              </div>
            </div>  
            <div className="col-lg-6 col-xl-4">
              <div className="tp-cta-2-right white-bg pt-135 pr-30 pl-75 pb-135">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-20 text-black">
                    we increase your business
                  </span>
                  <h2 className="tp-section-title tp-title-size ">
                  WHY CHOOSE SOLEMARK ACCOUNTANTS 
                  </h2>
                  <p className="text-black">
                  ●	Professional accountants backed by years of proven expertise and industry knowledge
                  </p>
                  <p className="pt-10 text-black">
                  ●	Revolutionizing accounting with innovative, tech-driven solutions
                  </p>
                  <p className="pt-10 text-black">
                  ●	Tailored solutions for your unique accounting needs
                  </p>
                  <p className="pt-10 text-black">
                  ●	Beyond bookkeeping: Adding strategic value to your business
                  </p>
                  <p className="pt-10 text-black">
                  ●	Commitment to on-time delivery: Timely insights for informed decision-making 
                  </p>
               <div className="pt-10">
                
               <img className="img-fluid w-100" src="assets/img/bg/xero.png" alt="" style={{ maxWidth: '400px' }} />
               </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopCategoryHomePageTwo;
